import React from 'react'
import "./Contact.css"
import { ContactUs, Hero } from "../../Components"
import { Container } from 'react-bootstrap';

const Contact = ({url, data}) => {
  const contactSection = data?.find(item => item.section === "Contact Us Section");

  return (
    <div className='contact-page'>
      <Hero
        mainTitle={"Home /"}
        title={"Contact us"}
        para={"CONTACT US"}
      />
      <Container>
        <ContactUs url={url} contactSection={contactSection} />
      </Container>
    </div>
  )
}

export default Contact