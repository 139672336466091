import React, { useState } from 'react';
import axios from 'axios';
import "./ResetPassword.css";
import { Container } from 'react-bootstrap';
import blueLock from "../../assets/images/svg/blueLock.svg";
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';


const ResetPassword = ({ url }) => {
  const { email, code } = useParams();
  const decodedEmail = decodeURIComponent(email);
  const decodedCode = decodeURIComponent(code);
  
  
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async () => {
    if (!decodedEmail || !decodedCode) {
      setError('Invalid reset link.');
      return;
    }

    if (password !== confirmPassword) {
      setError('Passwords do not match.');
      return;
    }

    try {
      const response = await axios.post(`${url}/`, {
        key: decodedCode,
        new_password: password,
        confirm_password: confirmPassword,
        cmd: 'frappe.core.doctype.user.user.update_password',
        email: decodedEmail
      });

      const { authentication, full_name, user_image, sid } = response.data;

      // Store api_key, api_secret, username, full_name, and user_image in cookies
      Cookies.set('api_key', authentication.api_key);
      Cookies.set('api_secret', authentication.api_secret);
      Cookies.set('full_name', full_name);
      Cookies.set('user_image', user_image);
      Cookies.set('sid', sid);

      window.location.href = '/dashboard';

    } catch (error) {
      setError('Password reset failed. Please try again.');
      console.error(error); // Log any errors from the request
    }
  };

  return (
    <div className='auth'>
      <div className='check-email-container'>
        <Container>
          <div className='reset-password-image'>
            <img src={blueLock} alt='Lock' />
          </div>
          <h1 className='check-email-title'>Reset Password</h1>
          <p className='check-email-para'>Please reset your password</p>
          <input
            type='password'
            className='login-field password-field'
            placeholder='Password'
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <input
            type='password'
            className='login-field password-field'
            placeholder='Confirm Password'
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
          {error && <p className='error-message'>{error}</p>}
          <input type='submit' value="Continue" className='login-submit' onClick={handleSubmit} />
        </Container>
      </div>
    </div>
  );
};

export default ResetPassword;
