import React, { useState, useEffect } from "react";
import "./Regulations.css";
import {
  Title,
  Text,
  RegulationNote,
  DatePickers,
} from "../../../../Components";
import regulation from "../../../../assets/images/svg/amendments.svg";
import arrowRight from "../../../../assets/images/svg/arrowRight.svg";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import axios from "axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";

const Regulations = ({ url, data }) => {
  const [regulations, setRegulations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isClear, setIsClear] = useState(false);

  const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  });
};


  const dashboardSection = data
    ? data?.find((item) => item.section === "Dashboard")
    : null;

  useEffect(() => {
    if (
      dashboardSection &&
      dashboardSection.data &&
      dashboardSection.data.content &&
      dashboardSection.data.content.regulations &&
      dashboardSection.data.content.regulations.objects
    ) {
      const regulationObjects =
        dashboardSection.data.content.regulations.objects.map((item) => {
          return {
            ...item,
          };
        });

      setRegulations(regulationObjects);
      setLoading(false);
    }
  }, [dashboardSection]);

  const handleResetDate = () => {
    setSelectedDate(null); // Reset the selected date
    setIsClear(true);
  };

  const [selectedDate, setSelectedDate] = useState({
    startDate: null,
    endDate: null,
  });

  const handleDateChange = (date) => {
    if (!date || !date.startDate || !date.endDate) {
      // Handle the case where date or startDate or endDate is null
      return;
    }

    // Function to format date as "Y-%m-%d %H:%M:%S"
    const formatDate = (inputDate) => {
      if (!inputDate) return null;
      const year = inputDate.getFullYear();
      const month = (inputDate.getMonth() + 1).toString().padStart(2, "0");
      const day = inputDate.getDate().toString().padStart(2, "0");

      return `${year}-${month}-${day}`;
    };

    // Set time to midnight for the start date and format it
    const startDateTime = date.startDate
      ? formatDate(new Date(date.startDate))
      : null;

    // Set time to midnight for the end date and format it
    const endDateTime = date.endDate
      ? formatDate(new Date(date.endDate))
      : null;

    setSelectedDate({
      startDate: startDateTime,
      endDate: endDateTime,
    });
  };

  useEffect(() => {
    if (
      (selectedDate &&
        (selectedDate.startDate !== null || selectedDate.endDate !== null)) ||
      (isClear && isClear === true)
    ) {
      fetchData();
    }
  }, [selectedDate, isClear]);

  const fetchData = async () => {
    const apiKey = Cookies.get("api_key");
    const apiSecret = Cookies.get("api_secret");
    try {
      let apiUrl = `${url}/api/method/projectx.projectx.doctype.federal_regulation.federal_regulation.federal_regulation`;
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `?&inforce_start_date=${selectedDate.startDate}&inforce_end_date=${selectedDate.endDate}`;
      }

      setLoading(true);
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
      if (response.data.status_code !== 200) {
        toast.error("Failed to fetch data. Please try again..");
      }

      const result = response.data.result;
      setRegulations(result?.objects ?? []);
      setLoading(false); // End loading
    } catch (error) {
      toast.error("Failed to fetch data. Please try again..");
      setLoading(false); // End loading
    }
  };

  const displayedRegulations = regulations.slice(0, 2);


  return (
    <div className="">
      <Title
        icon={regulation}
        title={"Regulations"}
        showInfo={true}
        titleBtn={true}
        onDateChange={handleDateChange}
        onReset={handleResetDate}
        tooltip={`"Regulations" provides access to regulations for all jurisdictions in Canada. Each jurisdiction has a dedicated page detailing the specific coverage available for it.`}
      />
      <div className="mt-3 hide-responsive">
        <Text para={"By data range:"} />
      </div>
      <div className="mt-2 hide-responsive">
        <DatePickers
          inputType={"datetime"}
          onDateChange={handleDateChange}
          onReset={handleResetDate}
        />
      </div>
      {/* Date picker and other UI components */}

      <div className="note-container">
        {loading ? (
          <>
            <div className="reminder-skelton">
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={18} />
                <Skeleton width={"100%"} height={13} />
              </div>
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={15} />
                <Skeleton width={"90%"} height={13} />
                <Skeleton width={"103%"} height={15} />
                <Skeleton width={"90%"} height={13} />
              </div>
            </div>
            <br />
            <div className="reminder-skelton">
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={18} />
                <Skeleton width={"100%"} height={13} />
              </div>
              <div className="first-reminder-skelton">
                <Skeleton width={"100%"} height={15} />
                <Skeleton width={"95%"} height={13} />
                <Skeleton width={"103%"} height={15} />
                <Skeleton width={"90%"} height={13} />
              </div>
            </div>
          </>
        ) : (
          <div className="dashboard-reminders">
            {regulations.length === 0 ? (
              <div className="not-found">No data available.</div>
            ) : (
              <>
                {displayedRegulations.map((regulation, index) => (
                  <RegulationNote
                    key={index}
                    value={regulation.name}
                    title={regulation.title}
                    textTitle={
                      regulation.areas_of_laws
                        ? regulation.areas_of_lawsdisplayedRegulations
                            .split(",")
                            .map((area) => area.trim())
                            .join(", ")
                        : ""
                    }
                    noteCount={regulation.notes_count}
                    reminderCount={regulation.reminders_count}
                    reportCount={"0"}
                    docName={regulation.name}
                    url={url}
                    titleLink={`/dashboard/regulation/${encodeURIComponent(regulation.name)}?${encodeURIComponent(regulation.regulation_type)}`}
                    date={formatDate(regulation.inforce_start_date)}
                    textDate={"in Force Date"}
                    onRefresh={fetchData}
                  />
                ))}

                <div className="note-link-container">
                  <Link
                    reloadDocument
                    to={"/dashboard/regulation"}
                    className="note-link"
                  >
                    Go To Regulations List <img src={arrowRight} alt="arrow" />
                  </Link>
                </div>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Regulations;
