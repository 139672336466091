import React, { useState } from 'react';
import axios from 'axios';
import './RegisterForm.css';
import { Container, Row, Col } from 'react-bootstrap';
import PhoneInput from "react-phone-number-input";
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import { SpinnerLoading } from "../../../Dashboard/Components"

const RegisterForm = ({ url, data }) => {
    const registerSection = data?.find(item => item.section === "Register Form");
    const { industry_types = [], company_sizes = [] } = (registerSection?.data?.content) || {};

    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        phoneNumber: "",
        email: "",
        organization: "",
        interest: "",
        industry: "",
        companySize: "",
        note: "",
        profilePicture: null,
        profilePictureBase64: "",
        profilePictureName: ""
    });

    const [formErrors, setFormErrors] = useState({});
    const [registrationMessage, setRegistrationMessage] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const navigate = useNavigate();

    const handleInputChange = (e) => {
        const { name, value, files } = e.target;
        if (name === 'profilePicture' && files.length > 0) {
            const file = files[0];
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePicture: file,
                profilePictureName: file.name
            }));
            convertFileToBase64(file);
        } else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value
            }));
        }
    };

    const convertFileToBase64 = (file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onloadend = () => {
            setFormData((prevFormData) => ({
                ...prevFormData,
                profilePictureBase64: reader.result
            }));
        };
    };

    const handlePhoneChange = (value) => {
        setFormData((prevFormData) => ({
            ...prevFormData,
            phoneNumber: value
        }));
    };

    const validateForm = () => {
        const errors = {};
        if (!formData.firstName) {
            errors.firstName = "First name is required";
        }
        if (!formData.lastName) {
            errors.lastName = "Last name is required";
        }
        if (!formData.phoneNumber) {
            errors.phoneNumber = "Phone number is required";
        }
        if (!formData.email) {
            errors.email = "Email is required";
        } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
            errors.email = "Email address is invalid";
        }
        if (!formData.organization) {
            errors.organization = "Organization is required";
        }
        if (!formData.industry) {
            errors.industry = "Industry is required";
        }
        if (!formData.companySize) {
            errors.companySize = "Company size is required";
        }
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!validateForm()) {
            return;
        }
        setIsSubmitting(true);
        const apiUrl = `${url}/api/method/erpnext.selling.doctype.customer.customer.company`;

        const data = {
            first_name: formData.firstName,
            last_name: formData.lastName,
            company_size: formData.companySize,
            company_name: formData.organization,
            location: formData.note,
            email: formData.email,
            phone_number: formData.phoneNumber,
            logo: {
                filename: formData.profilePictureName,
                data: formData.profilePictureBase64
            }
        };

        try {
            const response = await axios.post(apiUrl, data, {
                headers: {
                    'Content-Type': 'application/json'
                },
            });
            if (response.data && response.data.status_code === 200) {
                setRegistrationMessage(response.data.message);
                navigate('/checkemail', { state: { email: formData.email } });
            } else {
                setRegistrationMessage(response.data.message);
            }
        } catch (error) {
            setRegistrationMessage('Registration failed. Please try again.');
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            <form className="book-demo-form" onSubmit={handleSubmit}>
                {isSubmitting && 
                    <div className='loading-form'>
                        <SpinnerLoading /> 
                    </div> 
                }
                <Row>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                First Name <span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder="first name"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleInputChange}
                            />
                            {formErrors.firstName && <span className="register-error">{formErrors.firstName}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                Last Name <span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder="last name"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleInputChange}
                            />
                            {formErrors.lastName && <span className="register-error">{formErrors.lastName}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                Phone number <span>*</span>
                            </p>
                            <PhoneInput
                                defaultCountry={"CA"}
                                international={true}
                                className="contact-input-text"
                                value={formData.phoneNumber}
                                onChange={handlePhoneChange}
                            />
                            {formErrors.phoneNumber && <span className="register-error">{formErrors.phoneNumber}</span>}
                        </div>
                    </Col>
                    <Col md={6}>
                        <div className="contact-input">
                            <p className="contact-input-title">
                                Email Address<span>*</span>
                            </p>
                            <input
                                type="text"
                                className="contact-input-text"
                                placeholder="yourName@email.com"
                                name="email"
                                value={formData.email}
                                onChange={handleInputChange}
                            />
                            {formErrors.email && <span className="register-error">{formErrors.email}</span>}
                        </div>
                    </Col>
                    <div className="contact-input">
                        <p className="contact-input-title">Organization</p>
                        <input
                            type="text"
                            className="contact-input-text"
                            placeholder="Organization"
                            name="organization"
                            value={formData.organization}
                            onChange={handleInputChange}
                        />
                        {formErrors.organization && <span className="register-error">{formErrors.organization}</span>}
                    </div>
                    <div className="contact-input">
                        <p className="contact-input-title">Company Logo</p>
                        <input
                            type="file"
                            className="contact-input-text"
                            name="profilePicture"
                            onChange={handleInputChange}
                        />
                    </div>
                    <div className="contact-input">
                        <p className="contact-input-title">Industry</p>
                        <select
                            className="contact-input-text"
                            name="industry"
                            value={formData.industry}
                            onChange={handleInputChange}
                        >
                            <option value="">Select one</option>
                            {industry_types.map((industry, index) => (
                                <option key={index} value={industry.name}>
                                    {industry.name}
                                </option>
                            ))}
                        </select>
                        {formErrors.industry && <span className="register-error">{formErrors.industry}</span>}
                    </div>
                    <div className="contact-input">
                        <p className="contact-input-title">Company Size</p>
                        <select
                            className="contact-input-text"
                            name="companySize"
                            value={formData.companySize}
                            onChange={handleInputChange}
                        >
                            <option value="">Select one</option>
                            {company_sizes.map((company, index) => (
                                <option key={index} value={company.name}>
                                    {company.name}
                                </option>
                            ))}
                        </select>
                        {formErrors.companySize && <span className="register-error">{formErrors.companySize}</span>}
                    </div>
                    <div className="contact-input">
                        <p className="contact-input-title">Note</p>
                        <textarea
                            rows={"5"}
                            className="contact-input-text"
                            name="note"
                            value={formData.note}
                            onChange={handleInputChange}
                        ></textarea>
                    </div>
                    <div className="contact-input">
                        <input
                            type="submit"
                            value={isSubmitting ? "Registering..." : "Register"}
                            className="contact-submit"
                            disabled={isSubmitting}
                        />
                    </div>
                </Row>
            </form>
            {registrationMessage && <p>{registrationMessage}</p>}
        </div>
    );
};

export default RegisterForm;
