import React, { useState } from 'react';
import './Reports.css';
import axios from 'axios';
import Cookies from 'js-cookie';
import { Note, RemoveBtn, RemainingDays, ConfirmDelete } from '../../../../Components';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Modal from "react-modal"

const Reports = ({ url, name, date, textDate, title, textTitle, title2, para,disable, showButton, removeBtn, days, hours, onDeleteReminder, titleLink }) => {

  const [showPopup, setShowPopup] = useState(false);
  const [isDisabled, setIsDisabled] = useState(disable);
  const [buttonText, setButtonText] = useState(removeBtn);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const timeString = date.toLocaleTimeString([], {
      hour: "2-digit",
      minute: "2-digit",
    });

    return timeString !== "00:00"
      ? date.toLocaleString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
          hour: "2-digit",
          minute: "2-digit",
        })
      : date.toLocaleDateString(undefined, {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
  };

  const handleDelete = () => {
    if (isDisabled === 0) {
      setButtonText("Deactivate Reminder");
    } else {     
      setButtonText("Activate Reminder");
    }

    if (removeBtn === "Expired") {
      // Prevent further action if the reminder is expired
      return;
    }
    setShowPopup(true);
  };

  const confirmDelete = () => {
    if (new Date(date) < new Date()) {
      console.error("Error: Reminder cannot be created in the past.");
      setButtonText("Expired");
      return;
    }
  
    const apiUrl = `${url}/api/method/frappe.automation.doctype.reminder.reminder.reminder`;
    const data = {
      "name": name,
      "description": textTitle,
      "disable": !isDisabled,
      "remind_at": date,
    };
    const apiKey = Cookies.get('api_key');
    const apiSecret = Cookies.get('api_secret');
  
    axios
      .put(apiUrl, data, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      })
      .then((response) => {
        if (!isDisabled) {
          toast.success('Deactivated  Successfully.'); // Show success toast for activation
        } else {
          toast.success('Activated Successfully.'); // Show success toast for deactivation
        }
        onDeleteReminder();
      })
      .catch((error) => {
        console.error(error);
        toast.error('Failed to deactivate the reminder.'); // Show error toast
      });
  
    setShowPopup(false);
  };
  const cancelDelete = () => {
    setShowPopup(false);
  };



  return (
    <div className="reminders-content">
      <Note 
        url={url} 
        date={formatDate(date)} 
        textDate={textDate} 
        title={title} 
        textTitle={textTitle} 
        title2={title2} 
        para={para} 
        showButton={showButton} 
        titleLink={titleLink} />
      <div 
        className="reminders-info"
      >
        <div className="reminders-btn" onClick={removeBtn !== "Expired" ? handleDelete : null} disabled={removeBtn === "Expired"}>
          <RemoveBtn name={buttonText} />
        </div>
        <RemainingDays days={days} hours={hours} />
      </div>
      
    

      <Modal isOpen={showPopup} onRequestClose={cancelDelete}>
        <ConfirmDelete
          cancleDelete={cancelDelete}
          confirmDelete={confirmDelete}
          para={`Are you sure you want to ${isDisabled === 1 ? "activate" : "deactivate"} this?`}
          deletePara={isDisabled === 1 ? "Activate" : "Deactivate"}
          isDisabled={isDisabled}
        />
      </Modal>
    </div>
  );
};

export default Reports;