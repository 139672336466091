import React, { useState, useEffect } from 'react';
import './Results.css';
import { AddResultBtn, Report, Paginations, AddReport } from '../../../../Components';
import axios from 'axios';
import Skeleton from 'react-loading-skeleton'; 
import Modal from 'react-modal';
import Cookies from 'js-cookie';
import { toast } from "react-toastify";

// Debounce function
const useDebounce = (value, delay) => {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
};

const Results = ({ url, selectedFilters, selectedDate,searchText, dashboardData ,clearedDate}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showPopup, setShowPopup] = useState(false);
  const resultsPerPage = 20;
  const [initialDataLoaded, setInitialDataLoaded] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(false);


  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const debouncedSearchText = useDebounce(searchText, 500); // 500ms debounce delay

  useEffect(() => {
    // Extract the initial data from dashboardData
    if (!initialDataLoaded) {
      const searchSection = dashboardData?.find(
        (section) => section.section === 'Search List'
      );
      if (searchSection) {
        const initialData = searchSection.data.content.regulations.objects;
        const pagination = searchSection.data.content.regulations.pagination.metadata;
        setData(initialData);
        setCurrentPage(pagination.current_page);
        setTotalPages(pagination.total_pages);
        setInitialDataLoaded(true);
        setIsLoading(false);
      }
    }
  }, [dashboardData, initialDataLoaded]);

  useEffect(() => {
    if ((selectedFilters != {} && selectedFilters != undefined && Object.keys(selectedFilters).length > 0) || (selectedDate && (selectedDate.startDate !== null || selectedDate.endDate !== null)) || (clearedDate && clearedDate === true) || debouncedSearchText.length > 0) {
      fetchData();
    } else if (initialDataLoaded) {
      // Revert to initial data if searchText and filters are empty
      const searchSection = dashboardData?.find(
        (section) => section.section === 'Search List'
      );
      if (searchSection) {
        const initialData = searchSection.data.content.regulations.objects;
        const pagination = searchSection.data.content.regulations.pagination.metadata;
        setData(initialData);
        setCurrentPage(pagination.current_page);
        setTotalPages(pagination.total_pages);
        setIsLoading(false);
      }
    }
  }, [selectedFilters, debouncedSearchText,selectedDate]);

  const fetchData = async (pageNumber = 1) => {
    try {
      const filterParams = Object.keys(selectedFilters)
        .filter((type) => selectedFilters[type].length > 0)
        .map((type) => {
          if (Array.isArray(selectedFilters[type])) {
            return `${type}=${encodeURIComponent(
              selectedFilters[type].join(",")
            )}`;
          } else {
            return `${type}=${encodeURIComponent(selectedFilters[type])}`;
          }
        })
        .join("&");

      const start = (pageNumber - 1) * resultsPerPage;
      const apiKey = Cookies.get("api_key");
      const apiSecret = Cookies.get("api_secret");
      let apiUrl = `${url}/api/method/projectx.projectx.doctype.federal_regulation.federal_regulation.get_regulation_list?start=${start}&limit=${resultsPerPage}${
        debouncedSearchText
          ? `&search=${encodeURIComponent(debouncedSearchText)}`
          : ""
      }${filterParams ? `&${filterParams}` : ""}`;
      if (selectedDate && selectedDate.startDate && selectedDate.endDate) {
        apiUrl += `&inforce_start_date=${selectedDate.startDate}&inforce_end_date=${selectedDate.endDate}`;
      }

      setIsLoading(true); // Start loading
      const response = await axios.get(apiUrl, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `token ${apiKey}:${apiSecret}`,
        },
      });
      if (response.data.status_code !== 200) {
        toast.error("Failed to fetch data. Please try again..");
      }
      const result = response.data.result;

      setCurrentPage(result.pagination.metadata.current_page);
      setTotalPages(result.pagination.metadata.total_pages);
      setData(result.objects);
      setIsLoading(false); // End loading
    } catch (error) {
      toast.error("Failed to fetch data. Please try again..");
      setIsLoading(false); // End loading
    }
  };

  const onPageChange = (pageNumber) => {
    fetchData(pageNumber);
  };

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    handleResize(); // Check initial screen width on component mount
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="results">
      {isLoading ? (
        <>
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
          <div className="regulation-skeleton">
            <div className="first-regulation-skeleton">
              <Skeleton width={"100%"} height={17} />
              <Skeleton width={"100%"} height={17} />
            </div>
            <div className="second-regulation-skeleton">
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"100%"} height={16} />
              <Skeleton width={"70%"} height={16} />
            </div>
          </div>
          <br />
        </>
      ) : (
        <>
          {!data || data.length === 0 ? (
            <div className="not-found">There are no regulations.</div>
          ) : (
            <>
              <div onClick={togglePopup}>
                <AddResultBtn />
              </div>
              <Modal
                isOpen={showPopup}
                onRequestClose={togglePopup}
                contentLabel="Select Filter Popup"
              >
                <AddReport
                  url={url}
                  cancelBtn={togglePopup}
                  reportDoctype={"Federal Regulation"}
                  showAddNew={true}
                  selectedFilters={selectedFilters}
                />
              </Modal>

              {data.map((item, index) => (
                <Report
                  key={item.name}
                  index={index}
                  titleLink={`/dashboard/regulation/${encodeURIComponent(item.name)}?${encodeURIComponent(item.regulation_type)}`}
                  property={
                    item.areas_of_laws
                      ? item.areas_of_laws
                          .split(",")
                          .map((area) => area.trim())
                          .join(", ")
                      : ""
                  }
                  title={item.title}
                  para={item.long_title }
                  noteCount={item.notes_count}
                  reminderCount={item.reminders_count}
                  url={url}
                  docName={item.name}
                  onRefresh={fetchData}
                  isSmallScreen={isSmallScreen}
                  noteRegulation={item.regulation_type}

                />
              ))}
              <div className="d-flex justify-content-center mt-5">
                <Paginations
                  currentPage={currentPage}
                  totalPages={totalPages}
                  onPageChange={onPageChange}
                />
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default Results;
